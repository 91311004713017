<template>
    <section>
        <modal-lateral ref="modalDetalleCupo">
            <template slot="title-edit">
                <div class="row mx-0">
                    <div class="col-auto px-0">
                        <i class="icon-left f-20 text-white cr-pointer" @click="$emit('close')" />
                    </div>
                    <div class="col px-0 text-white">
                        <p> Detalle del cupón </p>
                    </div>
                </div>
            </template>
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 144px)">
                <div class="row align-items-center m-3 cr-pointer">
                    <div class="col-11 d-flex pl-0 border br-10">
                        <div class="bg-general d-middle-center br-l-10" style="width:93px;height:93px;">
                            <i :class="`icon-${ detalleCupon.descuento_tipo == 1 ? 'cupon-porcentaje' : 'coin'} f-30 text-white`" />
                        </div>
                        <div class="col">
                            <p class="text-general f-22 f-500 text-center">
                                <span> {{ detalleCupon.descuento_tipo == 2 ? '$' : '' }}</span>
                                {{ detalleCupon.descuento_valor }} 
                                <span> {{ detalleCupon.descuento_tipo == 1 ? '%' : '' }}</span>
                            </p>
                            <p class="f-14 text-general text-center"> {{ detalleCupon.nombre }} </p>
                            <p class="f-14 text-general f-600 my-1 tres-puntos text-center">
                                {{ detalleCupon.codigo }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mx-3 align-items-center">
                    <div class="col-auto pl-2">
                        <i class="icon-calendar-clock f-24 text-general" />
                    </div>
                    <div class="col pl-0 f-15 text-general">
                        Expira: {{ formatearFecha(detalleCupon.expira, 'DD MMMM Y') }}
                    </div>
                </div>
                <div class="row mx-3">
                    <div class="col-auto pl-2">
                        <i class="icon-cupon f-18 text-general" />
                    </div>
                    <div class="col pl-2 f-15 text-general">
                        Leeche: {{ detalleCupon.descripcion }}
                    </div>
                </div>
                <div class="row mx-3 align-items-center">
                    <div class="col-auto pl-2">
                        <i class="icon-currency-usd-circle f-24 text-general" />
                    </div>
                    <div class="col pl-0 f-15 text-general">
                        Compra mínima: {{ separadorNumero(detalleCupon.compra_minima) }}
                    </div>
                </div>
                <div class="row mx-3">
                    <div class="col-auto pl-2">
                        <i class="icon-package-variant-closed f-24 text-general" />
                    </div>
                    <div class="col pl-0 f-15 text-general">
                        {{ detalleCupon.productos }}
                    </div>
                </div>
            </div>
            <div class="row mx-0 py-2 justify-center text-general f-12 align-items-center">
                <i class="icon-info-circled-alt text-general f-18" />
                El cupón solo aplica sobre el valor de los productos.
            </div>
            <div class="row mx-0 justify-center">
                <div class="col-10 px-2">
                    <div class="bg-general text-white br-8 py-1 text-center cr-pointer shadow" @click="usarCupon">
                        Usar Cupón
                    </div>
                </div>
            </div>
        </modal-lateral>
        <!-- Partials -->
    </section>
</template>

<script>
import PedidosNew from "~/services/pedidos/pedidos-tendero";
export default {
    props: {
        detalleCupon: {
            type: Object,
            default: () => {}
        }
    },
    data(){
        return {}
    },
    watch: {
        detalleCupon(newValue){
            console.log(newValue);
        }
    },
    methods: {
        toggle(){
            this.$refs.modalDetalleCupo.toggle();
        },
        async usarCupon(){
            const { data } = await PedidosNew.seleccionar_cupon(this.detalleCupon.id);
            this.$emit('update')
            this.toggle()
        },
    }
}
</script>